/**
 *  Fonts
 */
$standard-heading: #000;
$standard-body: #606060;

/**
 *  Furniture
 */
$hi-grey: #f8f8f8; // - lightest grey
$standard-grey: #efefef;
$lo-grey: #d8d8d8; // - darkest grey

/**
 *  Actions
 */
$action-black: #000; // - button black
$action-grey: #414141; // - button grey
$action-white: #fff; // - button white
$action-pear: #c5d800; // - button light pear green

/**
 *  Loading
 */
$loading-background-color: #414141; // - darkest grey (eq. button grey)
$loading-border-color: #dedede; // - lightest grey
$loading-color: #fff; // - white

$loading-animation-background-color: #545454; // - animation grey
$loading-animation-border-color: #c5d800; // - animation light pear green
$loading-animation-color: #fff;

/**
 *  Accordions
 */
$accordion-background-color: #fff;
$accordion-border-color: #fff; // - darkest grey (lo-grey)
$accordion-color: #606060;

$accordion-title-background-color: #e4e4e4;
$accordion-title-color: #000;

$accordion-bellows-background-color: #fff;
$accordion-bellows-color: #606060;

$accordion-bellows-title-background-color: #fff;
$accordion-bellows-title-color: #606060;

/**
 *  Panels
 */
$panel-title: #000; // - black
$panel-price: #c5d800; // - light pear green

$panel-background-color: #fff;
$panel-border-color: #d8d8d8; // - darkest grey (lo-grey)
$panel-color: #000;

$panel-label-background-color: #fff;
$panel-label-color: #606060;

$panel-range-background-color: #fff;
$panel-range-color: #606060;

$panel-range-title-background-color: transparent;
$panel-range-title-color: #606060;

$panel-range-price-background-color: transparent;
$panel-range-price-color: #c5d800;

$panel-pack-description-background-color: transparent;
$panel-pack-description-color: #606060;

$panel-selected-background: #fff; // white
$panel-selected-border-color: #c5d800; // - light pear green

$panel-input-background-color: #fff; // white
$panel-input-border-color: #d8d8d8; // - darkest grey (lo-grey)
$panel-input-color: #c5d800; // - light pear green

$panel-input-disabled-background-color: #e4e4e4;
$panel-input-disabled-border-color: #e4e4e4;
$panel-input-disabled-color: #e4e4e4;

$panel-input-selected-background-color: #c5d800; // - light pear green
$panel-input-selected-border-color: #c5d800; // - light pear green
$panel-input-selected-color: #fff; // white

$panel-name-background-color: transparent;
$panel-name-color: #000; // - black

$panel-description-background-color: transparent;
$panel-description-color: #000; // - black

$panel-price-background-color: transparent;
$panel-price-color: #c5d800; // - light pear green

/**
 *  Header
 */
$header-background-color: #fff;

/**
 *  Footer
 */
$footer-background-color: #f8f8f8;
$footer-border-color: #d8d8d8;

/**
 *  Options
 */
$options-background-color: #fff;
$options-color: #606060;

/**
 *  Vieport
 */
$viewport-background-color: #f8f8f8;
$viewport-border-color: #dedede;

/**
 *  Menu
 */
$menu-background-color: #e4e4e4;
$menu-border-color: #d8d8d8;
$menu-color: #606060;

/**
 *  Menu Item
 */
$menu-item-title-heading-color: #000;
$menu-item-title-body-color: #606060;

/**
 *  Menu Item (Top Level)
 */
$menu-item-top-level-background-color: #e4e4e4;
$menu-item-top-level-border-color: #efefef;
$menu-item-top-level-color: #606060;

$menu-item-top-level-active-background-color: #fff;
$menu-item-top-level-active-color: #000;

/**
 *  Menu Item (Sub Level)
 */
$menu-item-sub-level-background-color: transparent;
$menu-item-sub-level-border-color: #efefef;
$menu-item-sub-level-color: #606060;

$menu-item-sub-level-active-background-color: transparent;
$menu-item-sub-level-active-color: #000;

/**
 *  Menu (View Summary)
 */
$menu-item-view-summary-background-color: #e4e4e4;
$menu-item-view-summary-border-color: #efefef;
$menu-item-view-summary-color: #606060;

$menu-item-view-summary-title-heading-color: #000;
$menu-item-view-summary-title-body-color: #606060;

$menu-item-view-summary-active-background-color: #fff;
$menu-item-view-summary-active-color: #000;

/**
 *  Menu (Send To A Retailer)
 */
$menu-item-send-to-a-retailer-background-color: #999;
$menu-item-send-to-a-retailer-border-color: #efefef;
$menu-item-send-to-a-retailer-color: #fff;

$menu-item-send-to-a-retailer-active-background-color: #fff;
$menu-item-send-to-a-retailer-active-color: #000;

/**
 *  Menu List
 */
$menu-list-background-color: #fff;
$menu-list-color: #606060;

$menu-list-title-background-color: #000;
$menu-list-title-color: #fff;

/**
 *  Menu List Item
 */
$menu-list-item-background-color: #fff;
$menu-list-item-border-color: #efefef;
$menu-list-item-color: #606060;

/**
 *  Menu View
 */
$menu-view-background-color: #fff;
$menu-view-color: #000;

/**
 *  Menu View Item
 */
$menu-view-item-background-color: #fff;
$menu-view-item-color: #000;

$menu-view-item-title-background-color: #fff;
$menu-view-item-title-border-color: #d8d8d8; // ::after {}
$menu-view-item-title-color: #000;

/**
 *  View
 */
$view-background-color: #fff;
$view-border-color: #efefef;
$view-color: #606060;

$view-actions-background-color: #f8f8f8;
$view-actions-color: #000;

$view-controls-background-color: #f8f8f8;
$view-controls-color: #000;

/**
 *  Summary
 */
$summary-background-color: #e5e5e5;
$summary-border-color: #dedede;

$summary-title-background-color: transparent;
$summary-title-color: #000;

/**
 *  Summary Preview
 */
$summary-preview-background-color: #e5e5e5;
$summary-preview-primary-color: #f8f8f8;
$summary-preview-accent-color: #e5e5e5;
$summary-preview-border-color: #dedede;

/**
 *  Summary Options
 */
$summary-options-background-color: #fff;
$summary-options-border-color: #efefef;

$summary-options-title-background-color: transparent;
$summary-options-title-color: #606060;

$summary-options-selected-background-color: #fff;
$summary-options-selected-border-color: #efefef;

$summary-options-selected-name-background-color: transparent;
$summary-options-selected-name-color: #606060;

$summary-options-selected-price-background-color: transparent;
$summary-options-selected-price-color: #c5d800; // - button light pear green

/**
 *  Price summary
 */
$price-summary-color: #606060;
$price-summary-total-color: #000;

/**
 *  Bloblets
 */
$colour-bloblet-background-color: transparent;
$colour-bloblet-border-color: rgba(0, 0, 0, .15);
$colour-bloblet-white: #fff;
$colour-bloblet-black: #000;

/**
 *  Caterham colours
 */
$acid-green: #d1dd27;
$admiralty-blue: #364451;
$aegean: #007ea4;
$arancio-argos: #f05440;
$atlantic-blue: #009;

$ballistic-orange: #f37221;
$birch-white: #f3f0e8;
$biscuit-beige: #e9d7c1;
$black: #000;
$black-grey: #292928;
$bordeaux-red: #2b1718;
$bright-red: #d21e12;
$burgundy: #5c0b1a;

$caribbean-blue: #7fa9bf;
$carmine-red: #a80812;
$cherry-red: #af0913;
$cobalt-blue: #0e134d;
$competition-red: #a9282d;
$cosmos-blue: #039;
$cream: #ccc;
$crystaline-white: #e4e0d7;
$cyclamen: #c06;

$dark-grey: #343e4a;
$light-grey: #999999;
$mid-blue: #6666CC;
$dark-blue: #000078;
$light-red: #CC0000;
$dark-red: #8C2633;
$light-green: #9EC400;
$dark-green: #506E33;
$brown: #7D5A32;
$dark-silver: #696c6c;
$denim-blue: #006;
$detonator-yellow: #fec353;
$dove-grey: #999;

$exocet-red: #d71e27;

$firecracker-yellow: #f3aa1e;
$forest-green: #033;

$ginger: #e0ac63;
$gold: #927f44;
$grass-green: #090;
$graphite: #4a5c68;
$gravity-black: #000;
$gulf-blue: #b6d1e3;
$gunmetal: #475057;

$hyper-green: #389e47;

$kelly-green: #063;

$lavender: #96c;
$light-aqua: #09c;
$light-blue: #39c;
$lime-green: #6c3;
$luminous-cyan: #0280ee;
$luminous-green: #01b847;
$luminous-orange: #fd690b;
$luminous-red: #ec1e1c;
$luminous-yellow: #ff0;

$marigold: #e16f0b;
$marina-blue: #03c;
$matt-black: #0a0d12;
$nardo-grey: #8b8889;

$olympic-blue: #09f;
$orange: #da580c;
$oxford-white: #fffbf0;

$peppermint: #6cc;
$peppermint-green: #54bab6;
$pink: #df8cc4;
$polar-white: #fff;
$primrose-yellow: #dfd912;

$racing-green: #11261f;
$riviera-blue: #1785c7;

$saxony-grey: #a79e7e;
$serengeti-orange: #e85211;
$silver: #87909f;
$sunflower-yellow: #ddae08;

$tomato-red: #c41416;
$turquoise: #01aac9;
$tuscany-red: #f6000f;

$ultra-violet: #282365;

$vintage-green: #004438;
$violet: #309;
$viper-blue: #242d68;
$volcano-red: #cc0202;

$warm-red: #ce2415;
$white: #fff;

$yellow: #e0c208;

$ashdown-green: #70a800;
$windsor-blue: #2b6ba5;
$beige: #d2c0a6;
$bourbon: #955b21;

$beechwood: #855845;
$biscuit: #b38f6d;
$niagara: #727274;
$peat: #4e4443;
$pillar-box: #ab4249;

@mixin biscuit {
  background-color: $biscuit;
}

@mixin beechwood {
  background-color: $beechwood;
}

@mixin peat {
  background-color: $peat;
}

@mixin niagara {
  background-color: $niagara;
}

@mixin pillarBox {
  background-color: $pillar-box;
}

@mixin kirktonBlackAndWhite($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin brodieHuntingWeathered($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin blueHighlandGranite($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin grahamOfMontroseWeathered($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin macGregorRobRoyAncient($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin macLeodDressAncient($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin napierAncient($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin thompsonRedAncient($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin perforatedBlackAlcantara($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin blackAlcantara($image-path) {
  background-image: url($image-path);
	background-repeat: no-repeat;
	background-size: cover;
}



@mixin acidGreen {
  background-color: $acid-green;
}

@mixin admiraltyBlue {
  background-color: $admiralty-blue;
}

@mixin aegean {
  background-color: $aegean;
}

@mixin atlanticBlue {
  background-color: $atlantic-blue;
}

@mixin arancioArgos {
  background-color: $arancio-argos;
}

@mixin ballisticOrange {
  background-color: $ballistic-orange;
}

@mixin birchWhite {
  background-color: $birch-white;
}

@mixin biscuitBeige {
  background-color: $biscuit-beige;
}

@mixin black {
  background-color: $black;
}

@mixin blackGrey {
  background-color: $black-grey;
}

@mixin brightRed {
  background-color: $bright-red;
}

@mixin bordeauxRed {
  background-color: $bordeaux-red;
}

@mixin burgundy {
  background-color: $burgundy;
}

@mixin carbon {
  background: url(../img/configurator/swatch-carbon.jpg) center center;
  background-size: cover;
}

@mixin caribbeanBlue {
  background-color: $caribbean-blue;
}

@mixin carmineRed {
  background-color: $carmine-red;
}

@mixin cherryRed {
  background-color: $cherry-red;
}

@mixin cobaltBlue {
  background-color: $cobalt-blue;
}

@mixin competitionRed {
  background-color: $competition-red;
}

@mixin cosmosBlue {
  background-color: $cosmos-blue;
}

@mixin cream {
  background-color: $cream;
}

@mixin crystalineWhite {
  background-color: $crystaline-white;
}

@mixin cyclamen {
  background-color: $cyclamen;
}

@mixin darkGrey {
  background-color: $dark-grey;
}

@mixin lightGrey {
  background-color: $light-grey;
}

@mixin midBlue {
  background-color: $mid-blue;
}

@mixin darkBlue {
  background-color: $dark-blue;
}

@mixin lightRed {
  background-color: $light-red;
}

@mixin darkRed {
  background-color: $dark-red;
}

@mixin lightGreen {
  background-color: $light-green;
}

@mixin darkGreen {
  background-color: $dark-green;
}

@mixin brown {
  background-color: $brown;
}

@mixin darkSilver {
  background-color: $dark-silver;
}

@mixin denimBlue {
  background-color: $denim-blue;
}

@mixin detonatorYellow {
  background-color: $detonator-yellow;
}

@mixin doveGrey {
  background-color: $dove-grey;
}

@mixin exocetRed {
  background-color: $exocet-red;
}

@mixin firecrackerYellow {
  background-color: $firecracker-yellow;
}

@mixin forestGreen {
  background-color: $forest-green;
}

@mixin ginger {
  background-color: $ginger;
}

@mixin gold {
  background-color: $gold;
}

@mixin graphite {
  background-color: $graphite;
}

@mixin grassGreen {
  background-color: $grass-green;
}

@mixin gravityBlack {
  background-color: $gravity-black;
}

@mixin gulfBlue {
  background-color: $gulf-blue;
}

@mixin gunmetal {
  background-color: $gunmetal;
}

@mixin hyperGreen {
  background-color: $hyper-green;
}

@mixin kellyGreen {
  background-color: $kelly-green;
}

@mixin lavender {
  background-color: $lavender;
}

@mixin lightAqua {
  background-color: $light-aqua;
}

@mixin lightBlue {
  background-color: $light-blue;
}

@mixin limeGreen {
  background-color: $lime-green;
}

@mixin luminousCyan {
  background-color: $luminous-cyan;
}

@mixin luminousGreen {
  background-color: $luminous-green;
}

@mixin luminousOrange {
  background-color: $luminous-orange;
}

@mixin luminousRed {
  background-color: $luminous-red;
}

@mixin luminousYellow {
  background-color: $luminous-yellow;
}

@mixin marigold {
  background-color: $marigold;
}

@mixin marinaBlue {
  background-color: $marina-blue;
}

@mixin mattBlack {
  background-color: $matt-black;
}

@mixin nardoGrey {
  background-color: $nardo-grey;
}

@mixin olympicBlue {
  background-color: $olympic-blue;
}

@mixin orange {
  background-color: $orange;
}

@mixin oxfordWhite {
  background-color: $oxford-white;
}

@mixin peppermint {
  background-color: $peppermint;
}

@mixin peppermintGreen {
  background-color: $peppermint-green;
}

@mixin pink {
  background-color: $pink;
}

@mixin polarWhite {
  background-color: $polar-white;
}

@mixin primroseYellow {
  background-color: $primrose-yellow;
}

@mixin racingGreen {
  background-color: $racing-green;
}

@mixin rivieraBlue {
  background-color: $riviera-blue;
}

@mixin saxonyGrey {
  background-color: $saxony-grey;
}

@mixin serengetiOrange {
  background-color: $serengeti-orange;
}

@mixin silver {
  background-color: $silver;
}

@mixin sunflowerYellow {
  background-color: $sunflower-yellow;
}

@mixin tomatoRed {
  background-color: $tomato-red;
}

@mixin turquoise {
  background-color: $turquoise;
}

@mixin tuscanyRed {
  background-color: $tuscany-red;
}

@mixin ultraviolet {
  background-color: $ultra-violet;
}

@mixin ultraViolet {
  background-color: $ultra-violet;
}

@mixin vintageGreen {
  background-color: $vintage-green;
}

@mixin violet {
  background-color: $violet;
}

@mixin viperBlue {
  background-color: $viper-blue;
}

@mixin volcanoRed {
  background-color: $volcano-red;
}

@mixin warmRed {
  background-color: $warm-red;
}

@mixin white {
  background-color: $white;
}

@mixin yellow {
  background-color: $yellow;
}

@mixin ashdownGreen {
  background-color: $ashdown-green;
}

@mixin windsorBlue {
  background-color: $windsor-blue;
}

@mixin beige {
  background-color: $beige;
}

@mixin bourbon {
  background-color: $bourbon;
}