$extra-small-max: 575.98px;

$small-min: 576px;
$small-max: 767.98px;

$medium-min: 768px;
$medium-max: 991.98px;

$large-min: 992px;
$large-max: 1199.98px;

$extra-large-min: 1200px;

/*
 *  "Extra Small"
 */
@mixin breakpointExtraSmall {
  /*
   *  To 576px
   */
  @media (max-width: $extra-small-max) { @content; }
}

/*
 *  "Extra Small (Portrait)"
 */
@mixin breakpointExtraSmallPortrait {
  /*
   *  To 576px
   */
  @media (max-width: $extra-small-max) and (orientation: portrait) { @content; }
}

/*
 *  "Extra Small (Landscape)"
 */
@mixin breakpointExtraSmallLandscape {
  /*
   *  To 576px
   */
  @media (max-width: $extra-small-max) and (orientation: landscape) { @content; }
}

/*
 *  "Small"
 */
@mixin breakpointSmall {
  /*
   *  Between 576px and 768px
   */
  @media (min-width: $small-min) and (max-width: $small-max) { @content; }
}

/*
 *  "Small (Portrait)"
 */
@mixin breakpointSmallPortrait {
  /*
   *  Between 576px and 768px
   */
  @media (min-width: $small-min) and (max-width: $small-max) and (orientation: portrait) { @content; }
}

/*
 *  "Small (Landscape)"
 */
@mixin breakpointSmallLandscape {
  /*
   *  Between 576px and 768px
   */
  @media (min-width: $small-min) and (max-width: $small-max) and (orientation: landscape) { @content; }
}

/*
 *  "Medium"
 */
@mixin breakpointMedium {
  /*
   *  Between 768px and 992px
   */
  @media (min-width: $medium-min) and (max-width: $medium-max) { @content; }
}

/*
 *  "Medium (Portrait)"
 */
@mixin breakpointMediumPortrait {
  /*
   *  Between 768px and 992px
   */
  @media (min-width: $medium-min) and (max-width: $medium-max) and (orientation: portrait) { @content; }
}

/*
 *  "Medium (Landscape)"
 */
@mixin breakpointMediumLandscape {
  /*
   *  Between 768px and 992px
   */
  @media (min-width: $medium-min) and (max-width: $medium-max) and (orientation: landscape) { @content; }
}

/*
 *  "Large"
 */
@mixin breakpointLarge {
  /*
   *  Between 992px and 1200px
   */
  @media (min-width: $large-min) and (max-width: $large-max) { @content; }
}

/*
 *  "Large (Portrait)"
 */
@mixin breakpointLargePortrait {
  /*
   *  Between 992px and 1200px
   */
  @media (min-width: $large-min) and (max-width: $large-max) and (orientation: portrait) { @content; }
}

/*
 *  "Large (Landscape)"
 */
@mixin breakpointLargeLandscape {
  /*
   *  Between 992px and 1200px
   */
  @media (min-width: $large-min) and (max-width: $large-max) and (orientation: landscape) { @content; }
}

/*
 *  "Extra Large"
 */
@mixin breakpointExtraLarge {
  /*
   *  From 1200px
   */
  @media (min-width: $extra-large-min) { @content; }
}

/*
 *  "Extra Large (Portrait)"
 */
@mixin breakpointExtraLargePortrait {
  /*
   *  From 1200px
   */
  @media (min-width: $extra-large-min) and (orientation: portrait) { @content; }
}

/*
 *  "Extra Large (Landscape)"
 */
@mixin breakpointExtraLargeLandscape {
  /*
   *  From 1200px
   */
  @media (min-width: $extra-large-min) and (orientation: landscape) { @content; }
}

@mixin breakpoint($name, $orientation: false) {
  /*
   *  "Extra Small"
   */
  @if $name == extra-small {
    /*
     *  To 576px
     */
    @if $orientation == 'portrait' {
      @media only screen and (max-width: $extra-small-max) and (orientation: portrait) { @content; }
    } @else if $orientation == 'landscape' {
      @media only screen and (max-width: $extra-small-max) and (orientation: landscape) { @content; }
    } @else {
      @media only screen and (max-width: $extra-small-max) { @content ; }
    }
  }

  /*
   *  "Small"
   */
  @if $name == small {
    /*
     *  From 576px
     */
    @if $orientation == 'portrait' {
      @media only screen and (min-width: $small-min) and (orientation: portrait) { @content; }
    } @else if $orientation == 'landscape' {
      @media only screen and (min-width: $small-min) and (orientation: landscape) { @content; }
    } @else {
      @media only screen and (min-width: $small-min) { @content ; }
    }

  }

  /*
   *  "Medium"
   */
  @if $name == medium {
    /*
     *  From 768px
     */
    @if $orientation == 'portrait' {
      @media only screen and (min-width: $medium-min) and (orientation: portrait) { @content; }
    } @else if $orientation == 'landscape' {
      @media only screen and (min-width: $medium-min) and (orientation: landscape) { @content; }
    } @else {
      @media only screen and (min-width: $medium-min) { @content ; }
    }
  }

  /*
   *  "Large"
   */
  @if $name == large {
    /*
     *  From 992px
     */
    @if $orientation == 'portrait' {
      @media only screen and (min-width: $large-min) and (orientation: portrait) { @content; }
    } @else if $orientation == 'landscape' {
      @media only screen and (min-width: $large-min) and (orientation: landscape) { @content; }
    } @else {
      @media only screen and (min-width: $large-min) { @content ; }
    }
  }

  /*
   *  "Extra Large"
   */
  @if $name == extra-large {
    /*
     *  From 1200px
     */
    @if $orientation == 'portrait' {
      @media only screen and (min-width: $extra-large-min) and (orientation: portrait) { @content; }
    } @else if $orientation == 'landscape' {
      @media only screen and (min-width: $extra-large-min) and (orientation: landscape) { @content; }
    } @else {
      @media only screen and (min-width: $extra-large-min) { @content ; }
    }
  }
}
