@import "./view/viewport";

%view {
  background-color: $view-background-color;
  color: $view-color;

  flex: 0 0 auto;

  .actions {
    @extend %actions;

    align-items: center;
    justify-content: center;

    font-family: "Open Sans Regular", sans-serif;
    font-style: normal;
    font-weight: 400;

    background-color: $view-actions-background-color;
    color: $view-actions-color;

    font-size: 0.875rem; // (14)
    line-height: 1.125rem; // (18)

    li {
      margin-left: 0;

      button {
        background-color: $view-actions-background-color;
        color: $view-actions-color;

        &.reset {
          color: $action-white;

          span {
            color: $action-white;

            &::before {
              content: "\e83a";
            }
          }
        }

        &.share {
          color: $action-black;

          span {
            color: $action-black;

            &::before {
              content: "\e83c";
            }
          }
        }

        &.print {
          color: $action-black;

          span {
            color: $action-black;

            &::before {
              content: "\e839";
            }
          }
        }

        &.reset:active,
        &.share:active,
        &.print:active {
          color: $action-pear;

          span {
            color: $action-pear;
          }
        }

        &.reset:hover,
        &.share:hover,
        &.print:hover {
          color: $action-white;

          span {
            color: $action-white;
          }
        }

        span {
          padding-left: 1.5rem;
        }
      }
    }

    @include breakpoint (large) {
      order: 1;

      position: absolute;
      top: 1rem;
      right: 2rem;

      padding: 0;

      background-color: transparent;

      li {
        margin-top: 0;
        margin-bottom: 0;

        &:last-of-type {
          margin-right: 0;
        }

        button {
          font-family: "Open Sans Regular", sans-serif;
          font-style: normal;
          font-weight: 400;

          // color: $standard-body;

          font-size: 0.875rem; // (14)
          line-height: 1.125rem; // (18)
        }
      }
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    font-family: "Open Sans Regular", sans-serif;
    font-style: normal;
    font-weight: 400;

    background-color: $view-controls-background-color;
    color: $view-controls-color;

    font-size: 0.875rem; // (14)
    line-height: 1.125rem; // (18)

    text-transform: uppercase;
    text-align: center;
    text-decoration: none;

    padding: 1rem 1rem 0 1rem;

    span {
      text-transform: uppercase;

      padding: 0 1rem;
    }

    button {
      position: relative;

      display: block;

      background-color: $action-grey;
      color: $action-white;
      border: 1px solid $action-grey;
      border-radius: 3rem;

      outline: 0;
      margin: 0;
      padding: 0;

      width: 2rem;
      height: 2rem;

      &.prev {
        &::after {
          transform: translate(-50%, -50%) rotate(270deg);
        }
      }

      &.next {
        &::after {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      span {
        display: none;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
        font-weight: 700;
        font-size: .875rem;
        speak: none;

        line-height: .875rem;

        content: "\e806";

        height: 1rem;

        overflow: hidden;
      }

      &:hover {
        transition: background-color .2s ease, color .2s ease, border-color .2s ease;

        background-color: $action-pear;
        color: $action-white;
        border-color: $action-pear;
      }

      &:active {
        transition: background-color .2s ease, color .2s ease, border-color .2s ease;

        background-color: $action-white;
        color: $action-pear;
        border-color: $action-white;
      }
    }

    @include breakpoint (large) {
      display: none;
    }
  }

  #viewport {
    @extend %viewport;
  }

  @include breakpoint (large) {
    height: calc(100vh - 5.5rem);
    width: 100%;

    flex: 0 1 100%;

    position: relative;

    order: 3;

    border-left: 1px solid $view-border-color;

    /*
     *  Above everything except `summary`
     */
    z-index: 3;
  }
}
