/*
 *  1 `summary`
 *  2 `footer`
 */
%header {
  position: relative;

  height: 6rem;
  width: 100%;

  display: block;
  overflow: hidden;

  background-color: $header-background-color;

  img {
    display: none;
  }

  @include breakpoint (large) {
    order: 2;

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    height: 6rem;
    width: 100%;

    background-color: transparent;

    z-index: 1;
  }
}
