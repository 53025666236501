%actionsAnchor {
  display: block;

  font-size: .75rem;
  line-height: 1.125rem;

  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  background-color: $action-pear;
  color: $action-white;
  border: 1px solid $action-pear;
  border-radius: 2rem;

  outline: 0;
  margin: 0;

  /*
   *  Left and right padding are three x the top and bottom padding (.375 is 1.125)
   */
  padding: .375rem 1.125rem;

  /**
   *  As per `components/actions/button`
   */
  @include breakpoint (medium) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // (20)

    /*
     *  Left and right padding are three x the top and bottom padding (.5 is 1.5)
     */
    padding: .5rem 1.5rem;
  }

  span {
    display: block;
  }

  &:active {
    background-color: $action-white;
    color: $action-pear;
    border-color: $action-white;

    transition: background-color .2s ease, color .2s ease, border-color .2s ease;
  }
}
