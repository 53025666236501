@import "breakpoints";
@import "palette";
@import "typography";

@import "components/actions";
@import "components/actions/anchor";

@import "elements/header";
@import "elements/footer";
@import "elements/renderapp";

// @import "debug";

@keyframes spinner {
  to {
    transform: rotate(360deg)
  }
}

*, ::before, ::after {
  margin: 0;
}

* {
  box-sizing: border-box;
}

::before, ::after {
  box-sizing: inherit;
}

/**
 *  Page structure favours small devices,
 *  so some CSS declarations modify the structure
 *  (specifally, element order) for large
 */
html,
body {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  margin: 0;
  padding: 0;
  border: none;

  width: 100%;
  height: auto;

  font-size: 16px;
  line-height: 22px; // + 6

  font-family: "Open Sans Regular", sans-serif;
  font-style: normal;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $standard-body;
  background-color: $standard-grey;
}

body.options-summary,
body.options-preview {
  main #renderapp .show#loading .alpha {
    left: 0;
  }
}

/**
 *  banner
 *    257 (176 + 40 + 40 + 1)
 *  content
 *    211
 *  configurator
 *    468
 */
@include breakpointExtraSmallPortrait {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          height: 29.25rem;
          display: block;
        }
      }
    }
  }
}

@include breakpointExtraSmallLandscape {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          height: 29.25rem;
          display: block;
        }
      }
    }
  }
}

@include breakpointSmallPortrait {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 29.25rem;
          height: 31.5625rem;
          display: block;
        }
      }
    }
  }
}

@include breakpointSmallLandscape {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 29.25rem;
          height: 31.5625rem;
          display: block;
        }
      }
    }
  }
}

/**
 *  Either
 *    banner
 *      231 (146 + 42 + 42 + 1)
 *    content
 *      433
 *    configurator
 *      664
 */
@include breakpointMediumPortrait {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 31.5625rem;
          height: 41.5rem;
          display: block;
        }
      }
    }
  }
}

@include breakpointMediumLandscape {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 31.5625rem;
          height: 41.5rem;
          display: block;
        }
      }
    }
  }
}

/**
 *  Or with pinned Prices
 *
 *    100vh - 135px
 *//*
@include breakpointMediumPortrait {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 31.5625rem;
          height: calc(100vh - 135px);
          display: block;
        }
      }
    }
  }
}

@include breakpointMediumLandscape {
  body.options-preview {
    main {
      height: auto;
      display: block;

      #renderapp {
        height: auto;
        display: block;

        #configurator {
          min-height: 31.5625rem;
          height: calc(100vh - 135px);
          display: block;
        }
      }
    }
  }
} */

main {
  display: block;
  height: auto;
  width: 100%;

  margin: 0;
  padding: 0;
  border: none;

  h1 {
    display: none;
  }

  header {
    @extend %header;
  }

  footer {
    @extend %footer;
  }

  #renderapp {
    @extend %renderapp;

    .configuration {
      display: none
    }
  }
}

@media print {
  body > * {
    display: none;
  }

  body {
    position: relative;

    main {
      display: block;

      background-color: #fff;

      #renderapp {
        background-color: transparent;

        #to-top {
          display: none;
        }

        #configurator {
          position: unset;

          display: block;

          width: 100%;
          height: auto;

          order: unset;
          z-index: unset;

          background-color: transparent;

          .configure,
          #summary-preview,
          #summary-options {
            display: none;
          }
        }

        footer {
          display: none;
        }
      }
    }
  }

  body.print-configuration {
    main {
      #renderapp {
        > * {
          display: none;
        }

        .configuration {
          display: flex;

          align-items: center;
          justify-content: center;

          width: 100vw;
          height: 100vh;

          border: none;
          margin: 0;
          padding: 0;
          outline: 0;

          overflow: hidden;

          img {
            flex: 0 0 auto;

            display: block;

            border: none;
            margin: 0;
            padding: 0;
            outline: 0;
          }
        }
      }
    }
  }

  body.print-options-summary {
    main {
       #renderapp {
        #configurator {
          #summary-options {
            position: unset;

            display: block;

            width: 100%;
            height: auto;

            order: unset;
            z-index: unset;

            p {
              display: none;
            }

            .actions {
              display: none;
            }

            h3 {
              display: none;
            }

            .banner {
              border: none;
              margin: 0;
              padding: 0;
              outline: 0;

              h2 {
                margin: 5.5rem 0 1.5rem 0;

                font-size: 1.5rem;
                line-height: 2rem;
              }
            }

            .groups {
              border: none;
              margin: 0;
              padding: 0;
              outline: 0;
              overflow-y: auto;

              .group:first-of-type {
                border-top: none;
              }

              .group {
                border-bottom: 1px solid #606060;

                h4,
                .selected {
                  font-size: 0.75rem;
                  line-height: 1.125rem;

                  color: #606060;

                  .price {
                    color: #606060;
                  }
                }
              }
            }
          }
        }

        footer {
          position: unset;

          display: block;

          width: 100%;
          height: auto;

          order: unset;
          z-index: unset;

          flex: unset;

          border: none;
          margin: 0;
          padding: 0;
          outline: 0;

          box-shadow: none;

          background-color: transparent;

          h2,
          .view-summary,
          .send-to-a-retailer,
          #to-top {
            display: none;
          }

          .price-summary::after {
            display: none;
          }

          dl {
            display: block;

            float: right;
            clear: both;
            width: auto;

            font-family: "Open Sans Regular", sans-serif;
            font-style: normal;
            font-weight: 400;

            font-size: 0.75rem;
            line-height: 1.125rem;

            margin: 1.5rem 0 0 0;
            padding: 0;

            white-space: nowrap;

            text-transform: uppercase;

            color: $price-summary-color;

            .item {
              display: block;

              float: left;
              clear: none;
              width: auto;

              dt, dd {
                display: inline;
              }

              &.base-price,
              &.options-price {
                max-height: 1.375rem;
              }

              &.base-price {
                margin-right: 1.125rem;
              }

              &.total-price {
                float: right;
                clear: both;
                width: auto;

                margin-top: .25rem;

                font-family: "Montserrat SemiBold", sans-serif;
                font-style: normal;
                font-weight: 400;

                font-size: 0.875rem;
                line-height: 1.125rem;

                color: $price-summary-total-color;

                dt::after {
                  content: ":";
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print and (orientation: portrait) {
  body.print-configuration {
    main {
      #renderapp {
        .configuration {
          img {
            width: 100vh;

            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

@media print and (orientation: landscape) {
  body.print-configuration {
    main {
      #renderapp {
        .configuration {
          img {
            width: 100vw;

            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
