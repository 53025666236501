%footer {
  position: relative;

  display: flex;

  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  flex: 0 0 auto;

  overflow: hidden;

  background-color: $footer-background-color;
  border-top: 1px solid $footer-border-color;

  padding: 1rem 2rem;

  box-shadow:
    0 0.375rem 0.375rem -0.125rem rgba(0, 0, 0, .15),
    0 0.25rem 0.875rem 0.125rem rgba(0, 0, 0, .075),
    0 .5rem 0.625rem 0.125rem transparent;

  /*
   *  Above `summary`
   *
   *  Specific for Firefox :(
   */
  z-index: 1;

  h2 {
    display: none;

    @include breakpoint (large) {
      display: block;

      flex: 0 0 auto;
      flex-wrap: nowrap;
      order: 1;

      padding: 0 2rem;
      margin: auto 0;

      font-family: "Montserrat SemiBold", sans-serif;
      font-style: normal;
      font-weight: 400;

      font-size: 1.375rem; // (22)
      line-height: 2.125rem; // (34)
    }
  }

  .view-summary {
    display: none;

    @include breakpoint (large) {
      display: block;

      flex: 0 0 auto;
      flex-wrap: nowrap;
      order: 3;

      overflow: hidden;

      margin: 0; /* 0.25rem 0 0.375rem 0; */
      padding: 1rem 2rem 1rem 1rem;

      button {
        display: block;
        float: left;
        clear: both;
        overflow: hidden;

        font-family: "Open Sans Bold", sans-serif;
        font-style: normal;
        font-weight: 400;

        font-size: 1rem;
        line-height: 1.125rem;

        text-transform: none;
        text-decoration: none;

        background-color: $action-pear;
        color: $action-white;
        border: 1px solid $action-pear;
        border-radius: 2rem;

        outline: 0;

        /*
         *  Left and right padding are three x the top and bottom padding (.375 is 1.1.25)
         */
        padding: .75rem 2.25rem;

        box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, .05), 0 .5rem 1rem 0 rgba(0, 0, 0, .05), 0 .5rem .5rem 0 rgba(0, 0, 0, .05);

        span {
          padding-right: 2rem;

          background: transparent url(../img/configurator/arrow-right.png) no-repeat center right;
          background-size: 1rem;

          text-align: left;

          display: block;
        }

        &:active {
          transition: background-color .2s ease, color .2s ease, border-color .2s ease;

          border-color: $action-white;
          color: $action-pear;
          background-color: $action-white;
        }
      }
    }
  }

  .price-summary {
    display: block;

    margin: .5rem 0 0 0;
    padding: 0 4rem 0 0;

    width: 100%;

    dl {
      display: block;

      font-family: "Open Sans Regular", sans-serif;
      font-style: normal;
      font-weight: 400;

      font-size: 0.75rem;
      line-height: 1.125rem;

      margin: 0;
      padding: 0;

      white-space: nowrap;

      text-transform: uppercase;

      color: $price-summary-color;

      .item {
        display: block;

        float: left;
        clear: none;
        width: auto;

        dt, dd {
          display: inline;
        }

        &.base-price,
        &.options-price {
          max-height: 1.375rem;
        }

        &.base-price {
          margin-right: 1.125rem;
        }

        &.total-price {
          float: none;
          clear: both;
          width: auto;

          margin-top: .25rem;

          font-family: "Montserrat SemiBold", sans-serif;
          font-style: normal;
          font-weight: 400;

          font-size: 1.5rem;
          line-height: 1.875rem;

          color: $price-summary-total-color;

          dt::after {
            content: ":";
          }
        }
      }
    }

    p.vat-notice {
      display: none;

      font-family: "Open Sans Regular", sans-serif;
      font-style: normal;
      font-weight: 400;

      font-size: 0.75rem;
      line-height: 1.125rem;

      margin: 0;
      padding: 0;

      color: $price-summary-color;
    }

    @include breakpoint (large) {
      display: flex;

      order: 2;

      align-items: flex-end;
      flex: 1 1 auto;
      flex-direction: column;
      flex-wrap: nowrap;

      margin: .5rem 0;
      padding: 0 1.125rem 0 0;

      position: relative;

      dl .item {
        text-align: right;

        float: unset;
        clear: unset;

        &.base-price,
        &.options-price {
          margin: 0;
        }

        &.total-price {
          font-size: 1.5rem; // (24)
          line-height: 1.875rem; // (30)
        }
      }

      &::after {
        position: absolute;
        content: "\2002";

        top: 1rem;
        right: 0;
        bottom: 1rem;

        width: 1px;

        background-color: #d8d8d8;
      }
    }
  }

  .send-to-a-retailer {
    display: block;
    white-space: nowrap;

    margin: 0 0 .5rem 0;
    padding: 0;

    button {
      @extend %actionsAnchor;
    }

    @include breakpoint (large) {
      display: none;
    }
  }

  @include breakpoint (large) {
    order: 1;

    align-items: center;
    flex: 0 0 5.5rem;
    flex-direction: row;

    margin: 0;
    padding: 0;

    border-bottom: 1px solid #ccc; // $lo-grey;

    // box-shadow: 0 .5rem .5rem -.25rem rgba(0, 0, 0, .1), 0 .25rem 1rem .25rem rgba(0, 0, 0, .1), 0 .5rem 1rem .25rem transparent;

    box-shadow:
      0 0.375rem 0.375rem -0.125rem rgba(0, 0, 0, .15),
      0 0.25rem 0.875rem 0.125rem rgba(0, 0, 0, .075),
      0 .5rem 0.625rem 0.125rem transparent;

    overflow: hidden;

    /*
     *  Above `header`
     *
     *  Specific for Firefox :(
     */
    z-index: 1;
  }
}
