@import "./menu-view-item/strap";
@import "./menu-view-item/stack";

%menuViewItem {
  position: absolute;

  display: block;

  background-color: $menu-view-item-background-color;
  color: $menu-view-item-color;

  width: 100%;
  height: auto;

  max-height: none;
  min-height: 100%;

  font-size: 0.75rem;
  line-height: 1.125rem;

  top: 0;
  left: 100%;
  right: -100%;

  overflow: visible;

  transition:
    left .5s ease-in-out,
    right .5s ease-in-out;

  &.initialise,
  &.active {
    left: 0;
    right: 0;

    z-index: 1;
  }

  &.initialise {
    transition: unset;
  }

  @include breakpoint (large) {
    max-height: 100%;
    min-height: 100%;

    &.active {
      transition:
        left .5s ease-in-out,
        right .5s ease-in-out;
    }
  }

  h3 {
    position: relative;

    display: block;

    margin: 0;
    padding: 0 0 0 5rem;

    background-color: $menu-view-item-title-background-color;
    color: $menu-view-item-title-color;

    text-transform: uppercase;

    cursor: pointer;

    span {
      display: block;
      padding: 1rem;

      cursor: default;

      @include breakpoint (large) {
        display: block;
        padding: 1.625rem 1rem;

        cursor: default;
      }
    }

    &::after {
      font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
      font-weight: 700;
      font-size: 1rem;
      speak: none;

      content: "\e802";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      padding: 1rem;

      text-align: center;

      width: 5rem;

      border-right: 1px solid $menu-view-item-title-border-color;
    }

    @include breakpoint (extra-small) {
      padding-left: 3rem;

      &::after {
        width: 3rem;
      }
    }

    @include breakpoint (large) {
      display: block;

      margin: 0;
      padding: 0;

      font-family: "Montserrat Bold", sans-serif;
      font-style: normal;
      font-weight: 400;

      font-size: 1rem; // (16)
      line-height: 1.375rem; // (22)

      cursor: default;

      &::after {
        display: none;
      }
    }
  }

  .strap {
    @extend %strap;
  }

  .stack {
    @extend %stack;
  }

  .group.disabled {
    display: none;
  }

  .group[data-group='gaitorsclr'],
  .group[data-group='sidepanelsclr'],
  .group[data-group='seatclrs'] {
    h5.title {
      display: none;
    }
  }

  @include breakpoint (large) {
    position: absolute;

    z-index: 0; // -1;

    overflow-x: hidden;
    overflow-y: auto;

    p {
      margin: 1rem;
    }
  }
}
