%actionsButton {
  display: block;

  font-size: .75rem;
  line-height: 1.125rem;

  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  background-color: transparent;
  color: $action-black;
  border: 1px solid $action-black;
  border-radius: 2rem;

  outline: 0;
  margin: 0;

  /*
   *  Left and right padding are three x the top and bottom padding (.375 is 1.125)
   */
  padding: .375rem 1.125rem;

  /**
   *  As per `components/actions/anchor`
   */
  @include breakpoint (medium) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // (20)

    /*
     *  Left and right padding are three x the top and bottom padding (.5 is 1.5)
     */
    padding: .5rem 1.5rem;
  }

  &.secondary {
    background-color: $action-grey;
    color: $action-white;
    border-color: $action-grey;
  }

  span {
    position: relative;

    display: block;

    &::before {
      font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
      font-weight: 700;
      font-size: 1rem;

      position: absolute;

      /*
       *  Put a 20px glyph beside an 18px glyph
       */
      top: -1px;
      bottom: -1px;
      left: 0;

      /*
       *  Put a 20px glyph beside an 20px glyph
       */
      @include breakpointMedium {
        top: 0;
        bottom: 0;
        left: 0;
      }

      @include breakpointLarge {
        top: 0;
        bottom: 0;
        left: 0;
      }

      @include breakpointExtraLarge {
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &:hover {
    transition: background-color .2s ease, color .2s ease, border-color .2s ease;

    background-color: $action-pear;
    color: $action-white;
    border-color: $action-pear;
  }

  &:active {
    transition: background-color .2s ease, color .2s ease, border-color .2s ease;

    background-color: $action-white;
    color: $action-pear;
    border-color: $action-white;
  }
}
