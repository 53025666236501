%viewport {
  position: relative;

  height: 20rem;

  background-color: $viewport-background-color;
  border: 1px solid $viewport-border-color;

  margin: 1px;
  padding: 0;

  overflow: hidden;

  #img1,
  #img2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    position: absolute;
    transition: all .5s ease;
    overflow: hidden;

    &.hide {
      opacity: 0;
    }

    &.show {
      opacity: 1;
    }

    & img {
      flex: 1 1 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  #trackpad {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .controls {
      display: none;

      @include breakpoint (large) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        font-family: "Open Sans Regular", sans-serif;
        font-style: normal;
        font-weight: 400;

        height: 100%;

        background-color: transparent;

        font-size: 0.875rem; // (14)
        line-height: 1.375rem; // (22)

        text-transform: uppercase;
        text-align: center;

        button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          display: block;

          background-color: $action-grey;
          color: $action-white;
          border: 1px solid $action-grey;
          border-radius: 3rem;

          font-size: inherit;
          line-height: inherit;

          outline: 0;
          margin: 0;
          padding: 0;

          width: 3rem;
          height: 3rem;

          &.prev {
            left: 3rem;

            &::after {
              margin-left: -2px; // tweak the position with a strategically applied margin :)
              transform: translate(-50%, -50%) rotate(270deg);
            }
          }

          &.next {
            right: 3rem;

            &::after {
              margin-left: 2px; // tweak the position with a strategically applied margin :)
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }

          span {
            display: none;
          }

          &::after {
            position: absolute;
            top: 50%;
            left: 50%;

            font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
            font-weight: 700;
            font-size: 1rem;
            speak: none;

            line-height: 1rem;

            content: "\e806";

            height: 1rem;

            text-align: center;
          }

          &:hover {
            transition: background-color .2s ease, color .2s ease, border-color .2s ease;

            border-color: $action-pear;
            color: $action-white;
            background-color: $action-pear;
          }

          &:active {
            transition: background-color .2s ease, color .2s ease, border-color .2s ease;

            border-color: $action-white;
            color: $action-pear;
            background-color: $action-white;
          }
        }
      }
    }
  }

  @include breakpoint (small) {
    height: 26rem;
  }

  @include breakpoint (medium) {
    height: 32rem;
  }

  @include breakpoint (large) {
    height: calc(100% - 96px);

    border: none;
    margin: 0;
    padding: 0;

    z-index: 0;
  }
}
