%menuList {
  display: block;

  order: 2;

  background-color: $menu-list-background-color;
  color: $menu-list-color;
  border: none;

  h2 {
    font-family: "Montserrat SemiBold", sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // (20)

    background-color: $menu-list-title-background-color;
    color: $menu-list-title-color;
    border: none;

    margin: 0;
    padding: 1rem 2rem;
  }

  ul {
    display: block;

    padding: 0;
    list-style-type: none;

    font-family: "Montserrat SemiBold", sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // (20)

    li {
      position: relative;

      display: block;

      background-color: $menu-list-item-background-color;
      color: $menu-list-item-color;
      border-bottom: 1px solid $menu-list-item-border-color;

      margin: 0;
      padding: 1rem 2rem;

      text-transform: uppercase;

      cursor: pointer;

      span {
        display: block;
        padding-right: 3rem;

        word-break: break-all;
      }

      &.choose-your-pack.disabled {
        display: none;
      }

      &::after {
        font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
        font-weight: 700;
        font-size: 1rem;
        speak: none;

        content: "\e804";
        position: absolute;

        width: 5rem;

        padding: 1rem 2rem;

        top: 0;
        right: 0;
      }
    }
  }

  @include breakpoint (large) {
    display: none;

    .choose-your-pack.disabled {
      display: none;
    }
  }
}
