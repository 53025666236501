@import "./menu-view/menu-view-item";

%menuView {
  display: block;

  width: 100%;
  height: auto;

  font-size: 0.75rem;
  line-height: 1.125rem;

  background-color: $menu-view-background-color;
  color: $menu-view-color;

  .menu-view-item {
    @extend %menuViewItem;
  }

  @include breakpoint (large) {
    display: block;

    width: 20rem;
    height: auto;

    background-color: $menu-view-background-color;
    color: $menu-view-color;
  }
}
