@import "./actions/button";

%actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  list-style-type: none;

  /*
   *  1 - 0.1875 = .8125
   */
  padding: .8125rem 2rem;

  li {
    display: block;

    margin: .1875rem;

    button {
      @extend %actionsButton;
    }
  }
}
