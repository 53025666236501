@import "./options/menu-list";
@import "./options/menu-view";

%options {
  position: relative;

  order: 1;

  background-color: $options-background-color;
  color: $options-color;

  flex: 0 0 auto;

  #menu-list {
    @extend %menuList;
  }

  #menu-view {
    @extend %menuView;
  }

  @include breakpoint (large) {
    height: calc(100vh - 5.5rem);
    width: 20rem;

    order: 2;

    flex: 0 0 20rem; /* 320 */

    z-index: 0;
  }
}
