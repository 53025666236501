@import "palette";

@font-face {
  font-family: "Montserrat Bold";
  src: url(../font/Montserrat-Bold.woff2) format("woff2"), url(../font/Montserrat-Bold.woff) format("woff"), url(../font/Montserrat-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url(../font/Montserrat-SemiBold.woff2) format("woff2"), url(../font/Montserrat-SemiBold.woff) format("woff"), url(../font/Montserrat-SemiBold.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Open Sans Bold";
  src: url(../font/OpenSans-Bold.woff2) format("woff2"), url(../font/OpenSans-Bold.woff) format("woff"), url(../font/OpenSans-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Open Sans Regular";
  src: url(../font/OpenSans-Regular.woff2) format("woff2"), url(../font/OpenSans-Regular.woff) format("woff"), url(../font/OpenSans-Regular.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Open Sans Light";
  src: url(../font/OpenSans-Light.woff2) format("woff2"), url(../font/OpenSans-Light.woff) format("woff"), url(../font/OpenSans-Light.otf) format("opentype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: fontello;
  src: url(../font/fontello.eot?37829658);
  src: url(../font/fontello.eot?37829658#iefix) format("embedded-opentype"), url(../font/fontello.woff2?37829658) format("woff2"), url(../font/fontello.woff?37829658) format("woff"), url(../font/fontello.ttf?37829658) format("truetype"), url(../font/fontello.svg?37829658#fontello) format("svg");
  font-style: normal;
  font-weight: 400
}

@mixin headingExtraLarge {
  font-family: "Montserrat Bold", sans-serif;
  color: $standard-heading;

  font-size: 2.75rem; // (44)
  line-height: 3.25rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 2rem; // (32)
    line-height: 2.5rem; // + 8
  }

  @include breakpoint (small) {
    font-size: 2rem; // (32)
    line-height: 2.5rem; // + 8
  }
}

@mixin headingLarge {
  font-family: "Montserrat Bold", sans-serif;
  color: $standard-heading;

  font-size: 2rem; // (32)
  line-height: 2.5rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1.5rem; // (24)
    line-height: 2rem; // + 8
  }

  @include breakpoint (small) {
    font-size: 1.5rem; // (24)
    line-height: 2rem; // + 8
  }
}

@mixin headingMedium {
  font-family: "Montserrat Bold", sans-serif;
  color: $standard-heading;

  font-size: 1.625rem; // (26)
  line-height: 2.125rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1.25rem; // (20)
    line-height: 1.75rem; // + 8
  }

  @include breakpoint (small) {
    font-size: 1.25rem; // (20)
    line-height: 1.75rem; // + 8
  }
}

@mixin headingSmall {
  font-family: "Montserrat Bold", sans-serif;
  color: $standard-heading;

  font-size: 1.25rem; // (20)
  line-height: 1.75rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1rem; // (16)
    line-height: 1.375rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 1rem; // (16)
    line-height: 1.375rem; // + 6
  }
}

@mixin headingExtraSmall {
  font-family: "Montserrat Bold", sans-serif;
  color: $standard-heading;

  font-size: 1rem; // (16)
  line-height: 1.375rem; // + 6

  @include breakpoint (extra-small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }
}

%headingExtraLarge { @include headingExtraLarge; }

%headingLarge { @include headingLarge; }

%headingMedium { @include headingMedium; }

%headingSmall { @include headingSmall; }

%headingExtraSmall { @include headingExtraSmall; }

@mixin bodyExtraLarge {
  font-family: "Open Sans Regular", sans-serif;
  color: $standard-body;

  font-size: 2rem; // (32)
  line-height: 2.5rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1.5rem; // (24)
    line-height: 2rem; // + 8
  }

  @include breakpoint (small) {
    font-size: 1.5rem; // (24)
    line-height: 2rem; // + 8
  }
}

@mixin bodyLarge {
  font-family: "Open Sans Regular", sans-serif;
  color: $standard-body;

  font-size: 1.5rem; // (24)
  line-height: 2rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1.125rem; // (18)
    line-height: 1.5rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 1.125rem; // (18)
    line-height: 1.5rem; // + 6
  }
}

@mixin bodyMedium {
  font-family: "Open Sans Regular", sans-serif;
  color: $standard-body;

  font-size: 1.25rem; // (20)
  line-height: 1.75rem; // + 8

  @include breakpoint (extra-small) {
    font-size: 1rem; // (16)
    line-height: 1.375rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 1rem; // (16)
    line-height: 1.375rem; // + 6
  }
}

@mixin bodySmall {
  font-family: "Open Sans Regular", sans-serif;
  color: $standard-body;

  font-size: 1rem; // (16)
  line-height: 1.375rem; // + 6

  @include breakpoint (extra-small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }
}

@mixin bodyExtraSmall {
  font-family: "Open Sans Regular", sans-serif;
  color: $standard-body;

  font-size: 0.875rem; // (14)
  line-height: 1.25rem; // + 6

  @include breakpoint (extra-small) {
    font-size: 0.75rem; // (12)
    line-height: 1.125rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 0.75rem; // (12)
    line-height: 1.125rem; // + 6
  }
}

%bodyExtraLarge { @include bodyExtraLarge; }

%bodyLarge { @include bodyLarge; }

%bodyMedium { @include bodyMedium; }

%bodySmall { @include bodySmall; }

%bodyExtraSmall { @include bodyExtraSmall; }

@mixin paragraph { @include bodyMedium; }

%paragraph { @include paragraph; }

h1,
.heading-large {
  @extend %headingLarge;

  text-transform: uppercase;

  margin: 1em 0;
}

h2,
.heading-medium {
  @extend %headingMedium;

  text-transform: uppercase;

  margin: 1em 0;
}

h3,
.heading-small {
  @extend %headingSmall;

  text-transform: uppercase;

  margin: 1em 0;
}

h4,
h5,
h6 {
  font-family: "Montserrat SemiBold", sans-serif;
  font-style: normal;
  font-weight: 400;

  text-transform: none;

  margin: 1em 0;

  font-size: 1rem; // (16)
  line-height: 1.375rem; // + 6

  @include breakpoint (extra-small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }

  @include breakpoint (small) {
    font-size: 0.875rem; // (14)
    line-height: 1.25rem; // + 6
  }
}

p,
.paragraph {
  @extend %paragraph;

  margin: 1em 0;
}

.body-large {
  @extend %bodyLarge;
}

.body-medium {
  @extend %bodyMedium;
}

.body-small {
  @extend %bodySmall;
}
