@import "./common";

%summaryOptions {
  @extend %summary;

  background-color: $summary-options-background-color;
  overflow-y: auto;

  .banner {
    border-bottom: 1px solid $summary-options-border-color;
  }

  @include breakpoint (large) {
    z-index: 4;
  }
}
