@import "components/tooltip";

@mixin group {
  .panel {
    background-color: $panel-background-color;
    color: $panel-color;

    select {
      margin: .5rem 1rem;
    }

    label {
      background-color: $panel-background-color;
      color: $panel-color;

      position: relative;

      display: block;

      min-height: 1.25rem;

      margin: 0;
      padding: 0.5rem 1rem;
      outline: 0;

      cursor: pointer;

      overflow: hidden;

      span {
        display: block;

        font-family: "Open Sans Regular", sans-serif;
        font-style: normal;
        font-weight: 400;

        color: $panel-title;

        font-size: 0.75rem; // (12)
        line-height: 1.125rem; // (18)

        text-align: left;

        &.input {
          float: left;
          clear: none;

          width: 2rem;
          height: 1.125rem;

          input {
            /**
             *  The relative element is the label so we're positioning the input smack in the center of the label,
             *  then moving it onto the layer below so it's invisible :)
             */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }

        &.name {
          display: inline;

          background-color: $panel-name-background-color;
          color: $panel-name-color;
        }

        &.description {
          display: block;

          background-color: $panel-description-background-color;
          color: $panel-description-color;

          margin: 2px 0 0 0;
        }

        &.price {
          display: block;

          float: right;
          clear: none;

          font-family: "Open Sans Bold", sans-serif;
          font-style: normal;
          font-weight: 700;

          background-color: $panel-price-background-color;
          color: $panel-price-color;

          font-size: 0.875rem; // (14)
          line-height: 1.125rem; // (18)

          text-align: right;

          padding-left: 1rem;
        }

        &.tooltip {
          @extend %tooltip;
        }
      }

      &::after {
        position: absolute;

        top: 0.4375rem;
        left: 1rem;

        width: 1.25rem;
        height: 1.25rem;

        font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
        font-size: 1rem;
        text-align: center;
        line-height: 1.125rem;

        background-color: $panel-input-background-color;
        color: $panel-input-color;
        border: 1px solid $panel-input-border-color;
        border-radius: 2px;

        content: "\2002";

        cursor: pointer;
      }

      /*
       *  Higher priority than no class, lower priority than `.disabled`
       */
      &.selected::after {
        background-color: $panel-input-selected-background-color;
        color: $panel-input-selected-color;

        border: 1px solid $panel-input-selected-border-color;
        border-radius: 2px;
        content: "\e813";
      }

      /*
       *  Higher priority than no class and `.selected`
       */
      &.disabled {
        display: none;
      }
    }

    &.colours {
      padding: 0 0.75rem;

      label {
        display: inline-block;

        width: 2rem;
        height: 2rem;

        min-height: unset;
        max-height: unset;

        min-width: unset;
        max-width: unset;

        margin: .25rem;
        padding: 0;

        background-color: $colour-bloblet-background-color;
        border: 1px solid $colour-bloblet-border-color;
        border-radius: 50%;

        cursor: pointer;

        /**
         *  Required for tooltips
         */
        overflow: visible;

        span {
          &.input,
          &.price,
          &.name,
          &.description {
            display: none;
          }
        }

        &::after {
          display: none;
        }

        &.acid-green {
          @include acidGreen;
        }

        &.admiralty-blue {
          @include admiraltyBlue;
        }

        &.aegean {
          @include aegean;
        }

        &.atlantic-blue {
          @include atlanticBlue;
        }

        &.arancio-argos {
          @include arancioArgos;
        }

        &.ballistic-orange {
          @include ballisticOrange;
        }

        &.birch-white {
          @include birchWhite;
        }

        &.biscuit-beige {
          @include biscuitBeige;
        }

        &.black {
          @include black;
        }

        &.black-grey {
          @include blackGrey;
        }

        &.bright-red {
          @include brightRed;
        }

        &.bordeaux-red {
          @include bordeauxRed;
        }

        &.burgundy {
          @include burgundy;
        }

        &.carbon {
          @include carbon;
        }

        &.caribbean-blue {
          @include caribbeanBlue;
        }

        &.carmine-red {
          @include carmineRed;
        }

        &.cherry-red {
          @include cherryRed;
        }

        &.cobalt-blue {
          @include cobaltBlue;
        }

        &.competition-red {
          @include competitionRed;
        }

        &.beechwood {
          @include beechwood;
        }

        &.biscuit {
          @include biscuit;
        }

        &.niagara {
          @include niagara;
        }

        &.peat {
          @include peat;
        }

        &.pillar-box {
          @include pillarBox;
        }

        &.kirkton-black-and-white {
          @include kirktonBlackAndWhite('../img/configurator/KirktonBlackAndWhiteTweed.jpg');
        }

        &.brodie-hunting-weathered {
          @include brodieHuntingWeathered('../img/configurator/BrodieHuntingWeathered.jpg');
        }

        &.blue-highland-granite {
          @include blueHighlandGranite('../img/configurator/BlueHighlandGranite.jpg');
        }

        &.graham-of-montrose-weathered {
          @include grahamOfMontroseWeathered('../img/configurator/GrahamOfMontroseWeathered.jpg');
        }

        &.macgregor-rob-roy-ancient {
          @include macGregorRobRoyAncient('../img/configurator/MacGregorRobRoyAncient.jpg');
        }

        &.macleod-dress-ancient {
          @include macLeodDressAncient('../img/configurator/MacLeodDressAncient.jpg');
        }

        &.napier-ancient {
          @include napierAncient('../img/configurator/NapierAncient.jpg');
        }

        &.thompson-red-ancient {
          @include thompsonRedAncient('../img/configurator/ThompsonRedAncient.jpg');
        }

        &.perforated-black-alcantara {
          @include perforatedBlackAlcantara('../img/configurator/PerforatedBlackAlcantara.jpg');
        }

        &.black-alcantara {
          @include blackAlcantara('../img/configurator/BlackAlcantara.jpg');
        }

        &.cosmos-blue {
          @include cosmosBlue;
        }

        &.cream {
          @include cream;
        }

        &.crystaline-white {
          @include crystalineWhite;
        }

        &.cyclamen {
          @include cyclamen;
        }

        &.dark-grey {
          @include darkGrey;
        }

        &.light-grey {
          @include lightGrey;
        }

        &.mid-blue {
          @include midBlue;
        }

        &.dark-blue {
        @include darkBlue;
        }

        &.light-red {
          @include lightRed;
        }

        &.dark-red {
          @include darkRed;
        }

        &.light-green {
          @include lightGreen;
        }

        &.dark-green {
          @include darkGreen;
        }

        &.brown {
          @include brown;
        }

        &.dark-silver {
          @include darkSilver;
        }

        &.denim-blue {
          @include denimBlue;
        }

        &.detonator-yellow {
          @include detonatorYellow;
        }

        &.dove-grey {
          @include doveGrey;
        }

        &.exocet-red {
          @include exocetRed;
        }

        &.firecracker-yellow {
          @include firecrackerYellow;
        }

        &.forest-green {
          @include forestGreen;
        }

        &.ginger {
          @include ginger;
        }

        &.gold {
          @include gold;
        }

        &.graphite {
          @include graphite;
        }

        &.grass-green {
          @include grassGreen;
        }

        &.gravity-black {
          @include gravityBlack;
        }

        &.gulf-blue {
          @include gulfBlue;
        }

        &.gunmetal {
          @include gunmetal;
        }

        &.hyper-green {
          @include hyperGreen;
        }

        &.kelly-green {
          @include kellyGreen;
        }

        &.lavender {
          @include lavender;
        }

        &.light-aqua {
          @include lightAqua;
        }

        &.light-blue {
          @include lightBlue;
        }

        &.lime-green {
          @include limeGreen;
        }

        &.luminous-cyan {
          @include luminousCyan;
        }

        &.luminous-green {
          @include luminousGreen;
        }

        &.luminous-orange {
          @include luminousOrange;
        }

        &.luminous-red {
          @include luminousRed;
        }

        &.luminous-yellow {
          @include luminousYellow;
        }

        &.marigold {
          @include marigold;
        }

        &.marina-blue {
          @include marinaBlue;
        }

        &.matt-black {
          @include mattBlack;
        }

        &.nardo-grey {
          @include nardoGrey;
        }

        &.olympic-blue {
          @include olympicBlue;
        }

        &.orange {
          @include orange;
        }

        &.oxford-white {
          @include oxfordWhite;
        }

        &.peppermint {
          @include peppermint;
        }

        &.peppermint-green {
          @include peppermintGreen;
        }

        &.pink {
          @include pink;
        }

        &.polar-white {
          @include polarWhite;
        }

        &.primrose-yellow {
          @include primroseYellow;
        }

        &.racing-green {
          @include racingGreen;
        }

        &.riviera-blue {
          @include rivieraBlue;
        }

        &.saxony-grey {
          @include saxonyGrey;
        }

        &.serengeti-orange {
          @include serengetiOrange;
        }

        &.silver {
          @include silver;
        }

        &.sunflower-yellow {
          @include sunflowerYellow;
        }

        &.tomato-red {
          @include tomatoRed;
        }

        &.turquoise {
          @include turquoise;
        }

        &.tuscany-red {
          @include tuscanyRed;
        }

        &.ultraviolet {
          @include ultraviolet;
        }

        &.ultra-violet {
          @include ultraViolet;
        }

        &.vintage-green {
          @include vintageGreen;
        }

        &.violet {
          @include violet;
        }

        &.viper-blue {
          @include viperBlue;
        }

        &.volcano-red {
          @include volcanoRed;
        }

        &.warm-red {
          @include warmRed;
        }

        &.white {
          @include white;
        }

        &.yellow {
          @include yellow;
        }

        &.ashdown-green {
          @include ashdownGreen;
        }

        &.windsor-blue {
          @include windsorBlue;
        }

        &.beige {
          @include beige;
        }

        &.bourbon {
          @include bourbon;
        }

        &.selected {
          &::after {
            display: block;

            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            font-size: 1rem;
            line-height: 1.25rem;

            background-color: transparent;
            color: $colour-bloblet-white;
            border: none;
          }

          &.white,
          &.polar-white,
          &.crystaline-white,
          &.oxford-white,
          &.birch-white,
          &.biscuit-beige {
            &::after {
              color: $colour-bloblet-black;
            }
          }
        }

        &.disabled {
          display: none;
        }
      }

      .bloblets {
        width: 12.5rem;
      }
    }

    /**
     *  `Pack` group with delimited description
     */
    .pack {
      margin-bottom: .5rem;

      label {
        &.thumbnail {
          min-height: 2.25rem;
          padding: 0.5rem 1rem;

          img {
            position: absolute;

            width: auto;
            height: 20px;

            top: 0.5rem;
            left: 3rem;
          }

          &::after {
            top: .5rem;
            left: 1rem;
          }
        }

        .name {
          display: none;
        }
      }

      /*
       *  Higher priority than no class, lower priority than `.disabled`
       */
      &.selected {
        label::after {
          background-color: $panel-input-selected-background-color;
          color: $panel-input-selected-color;
          border: 1px solid $panel-input-selected-border-color;
          border-radius: 2px;
          content: "\e813";
        }
      }

      /*
       *  Higher priority than no class and `.selected`
       */
      &.disabled {
        display: none;
      }

      .description {
        font-family: "Open Sans Regular", sans-serif;
        font-weight: 400;

        background-color: $panel-pack-description-background-color;
        color: $panel-pack-description-color;

        font-size: 0.875rem; // (14)
        line-height: 2rem; // (32) - 1.75rem; // (28) - 1.25rem; // (20)

        padding-right: 1rem;
      }
    }

    /**
     *  `PaintColours` group with title
     */
    .range {
      &:first-of-type h6 {
        margin-top: 0;
      }

      /*
       *  Higher priority than no class and `.selected`
       */
      &.disabled {
        display: none;
      }
    }
  }

  .label {
    /**
     *  Invisible by default for `.label`
     */
    display: none;

    &.colours {
      /**
       *  Visible by default for `.label.colours`
       */
      display: block;

      padding: 0 1rem .5rem 1rem;
    }
  }
}
