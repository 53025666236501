@import "./renderapp/configurator";
@import "./renderapp/loading";

%renderapp {
  position: relative;

  display: flex;
  flex-direction: column;

  &.dealership,
  &.vr,
  &.plus {
    #configurator .configure {
      #menu .send-to-a-retailer {
        display: none;
      }

      #view {
        .controls {
          display: none;
        }

        #viewport {
          background-repeat: no-repeat;
          background-position: center;

          #img1,
          #img2,
          #gameContainer,
          #trackpad .controls {
            display: none;
          }
        }
      }
    }
  }

  &.vr,
  &.plus {
    #configurator .configure #view #viewport #webglcameras {
      display: none;
    }
  }

  &.web,
  &.prerender {
   #configurator .configure #view #viewport {
      #gameContainer,
      #webglcameras {
        display: none;
      }
    }
  }

  &.webgl {
    #configurator .configure #view #viewport #trackpad {
      display: none;

      .controls {
        display: none;
      }
    }
  }

  &.review {
    #configurator .configure #view .controls {
      display: none;
    }

    #configurator .configure #view #viewport {
      #img1,
      #img2,
      #gameContainer,
      #trackpad,
      #trackpad .controls,
      #webglcameras {
        display: none;
      }
    }
  }

  &.dealership #configurator .configure #view #viewport {
    background-image: url(../img/viewport/viewport_dealership.png);
  }

  &.vr #configurator .configure #view #viewport {
    background-image: url(../img/viewport/viewport_vr.png);
  }

  &.plus #configurator .configure #view #viewport {
    background-image: url(../img/viewport/viewport_plus.png);
  }

  &.web header,
  &.webgl header {
    background-color: #fff;

    img {
      display: block;

      width: 7rem;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  #configurator {
    @extend %configurator;
  }

  #loading {
    @extend %loading;
  }

  /**
   *  England does not have a tax message ...
   */
  &.en {
    #configurator .configure {
      #view {
        @include breakpoint (large) {
          height: calc(100vh - 5.5rem); // 88px
        }
      }

      #menu {
        @include breakpoint (large) {
          height: calc(100vh - 5.5rem); // 88px
        }
      }

      #options {
        @include breakpoint (large) {
          height: calc(100vh - 5.5rem); // 88px
        }
      }
    }
  }

  /**
   *  Whereas France does have a tax message ...
   */
  &.fr {
    #configurator .configure {
      #view {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }

      #menu {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }

      #options {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }
    }

    footer {
      @include breakpoint (large) {
        flex: 0 0 7.5rem;
      }

      @include breakpoint (extra-large) {
        flex: 0 0 6.375rem;
      }

      .price-summary {
        dl .item.total-price {
          font-size: 1.25rem; // (20)
          line-height: 1.625rem; // (26)
        }

        p.vat-notice.fr {
          display: block;
        }
      }
    }
  }

  /**
   *  ... which is distinct from the tax message for these countries
   */
  &.at,
  &.be,
  &.cz,
  &.de,
  &.it,
  &.nl,
  &.se,
  &.pt,
  &.es {
    #configurator .configure {
      #view {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }

      #menu {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }

      #options {
        @include breakpoint (large) {
          height: calc(100vh - 7.5rem); // 120px
        }

        @include breakpoint (extra-large) {
          height: calc(100vh - 6.375rem); // 102px
        }
      }
    }

    footer {
      @include breakpoint (large) {
        flex: 0 0 7.5rem;
      }

      @include breakpoint (extra-large) {
        flex: 0 0 6.375rem;
      }

      .price-summary {
        dl .item.total-price {
          font-size: 1.25rem; // (20)
          line-height: 1.625rem; // (26)
        }

        p.vat-notice.at,
        p.vat-notice.be,
        p.vat-notice.cz,
        p.vat-notice.de,
        p.vat-notice.it,
        p.vat-notice.nl,
        p.vat-notice.se,
        p.vat-notice.pt {
          display: block;
        }
      }
    }
  }

  &.en { // stylelint-disable-line no-duplicate-selectors
    #loading.show .alpha {
      @include breakpoint (large) {
        top: 5.5rem; // 88px
      }
    }
  }

  &.fr,
  &.at,
  &.be,
  &.cz,
  &.de,
  &.it,
  &.nl,
  &.se,
  &.pt {
    #loading.show .alpha {
      @include breakpoint (large) {
        top: 7.5rem; // 120px
      }

      @include breakpoint (extra-large) {
        top: 6.375rem; // 102px
      }
    }
  }

  #exit-summary,
  #to-top {
    display: block;

    white-space: nowrap;

    font-family: "Open Sans Regular", sans-serif;
    font-style: normal;
    font-weight: 400;

    color: $standard-body;

    font-size: 0.875rem; // (14)
    line-height: 1.125rem; // (18)

    text-transform: uppercase;
    text-align: center;
    text-decoration: none;

    outline: 0;
    margin: 0;
    padding: 0;

    &:active {
      transition: background-color .2s ease, color .2s ease, border-color .2s ease;
    }
  }

  #exit-summary {
    position: absolute;

    top: 1rem;
    right: 2rem;

    width: 2rem;
    height: 2rem;

    background-color: $action-grey;
    color: $action-white;
    border: 1px solid $action-grey;
    border-radius: 2rem;

    span {
      display: none;
    }

    &:active {
      background-color: $action-white;
      color: $action-grey;
      border-color: $action-white;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
      font-weight: 700;
      font-size: .875rem;
      speak: none;

      line-height: .875rem;

      content: "\2715";

      height: 1rem;

      overflow: hidden;

      margin-top: 1px;
    }

    @include breakpoint (extra-small) {
      top: .5rem;
      right: 1rem;
    }
  }

  #to-top {
    position: absolute;

    bottom: 1rem;
    right: 2rem;

    width: 2rem;
    height: 2rem;

    background-color: $action-pear;
    color: $action-white;
    border: 1px solid $action-pear;
    border-radius: 2rem;

    span {
      display: none;
    }

    &:active {
      background-color: $action-white;
      color: $action-pear;
      border-color: $action-white;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
      font-weight: 700;
      font-size: .875rem;
      speak: none;

      line-height: .875rem;

      content: "\e806";

      height: 1rem;

      overflow: hidden;
    }

    @include breakpoint (large) {
      display: none;
    }
  }
}
