/**
 *  Only applies to `#models`
 */
%stack {
  @mixin panel {
    background-color: $panel-background-color;
    color: $panel-color;

    /**
     *  6.625rem (16 + 74 + 16)
     */
    label {
      padding: 0.9375rem 1rem 1rem 1rem; // (15 16 16 16)

      background-color: $panel-background-color;
      color: $panel-color;

      border: none;
      border-top: 1px solid $panel-border-color;

      &:last-of-type {
        border-bottom: 1px solid $panel-border-color;
      }

      img {
        display: none;
      }

      &::after {
        top: 0.9375rem; // 15
        left: 1rem; // 16
      }

      &.selected {
        padding: 0.875rem; // (14)

        border: 2px solid $panel-selected-border-color;

        &::after {
          top: 0.875rem; // 14
          left: 0.875rem; // 14
        }
      }

      &.thumbnail {
        img {
          width: 204px;
          height: 74px;

          display: block;

          border: none;
          margin: 0 auto 1rem auto;
          padding: 0;
          outline: 0;
        }

        &::after {
          top: 6.5rem; // 104 // 6.5625rem; // 105
        }

        &.selected {
          &::after {
            top: 6.4375rem; // 103
          }
        }
      }

      @include breakpoint (large) {
        &.thumbnail {
          img {
            width: 220px;
            height: 80px;
          }

          &::after {
            top: 6.875rem; // 110 // 6.9375rem; // 111
          }

          &.selected {
            &::after {
              top: 6.8125rem; // 109
            }
          }
        }
      }
    }
  }

  width: 100%;
  height: auto;

  max-height: none;
  min-height: auto;

  overflow-x: hidden;
  overflow-y: visible;

  font-family: "Open Sans Regular", sans-serif;
  font-style: normal;
  font-weight: 400;

  color: $standard-body;

  font-size: 0.75rem;
  line-height: 1.125rem;

  /**
   *  Models
   */
  .group {
    @include group;

    .panel {
      @include panel;
    }
  }

  @include breakpoint (extra-small) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    #chooseyourseven:after {
      content: "Configure your Seven";
    }
  }

  @include breakpoint (small) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    #chooseyourseven:after {
      content: "Configure your Seven";
    }
  }

  @include breakpoint (medium) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    #chooseyourseven:after {
      content: "Configure your Seven";
    }
  }

  @include breakpoint (large) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    #chooseyourseven:after {
      content: "Choose your Seven";
    }
  }
}
