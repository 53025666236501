%loading {
  display: none;

  z-index: -1;

  &.show {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;

    z-index: 3;

    .alpha {
      position: absolute;

      top: 98px;
      left: 2px;
      right: 2px;

      height: 19.875rem; // 20rem

      display: block;

      .omega {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: $loading-background-color;
        color: $loading-color;
        border: 1px solid $loading-border-color;
        border-radius: .5rem;

        font-size: 1.5rem;
        line-height: 2rem;

        padding: 1rem;

        min-width: 13.125rem;
        min-height: 4.125rem;

        .animation {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: center;

          padding-left: 3rem;

          &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            content: "\2002";

            animation: spinner .7s linear infinite;
            background: $loading-animation-background-color;
            color: $loading-animation-color;
            border-radius: 50%;
            border-right: .25rem solid transparent;
            border-top: .25rem solid $loading-animation-border-color;
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }
  }

  @include breakpoint (small) {
    &.show .alpha {
      height: 25.875rem; // 26rem
    }
  }

  @include breakpoint (medium) {
    &.show .alpha {
      height: 31.875rem; // 32rem
    }
  }

  @include breakpoint (large) {
    &.show {
      position: fixed;

      .alpha {
        position: absolute;
        top: 5.5rem;
        left: 481px;
        right: 0;
        bottom: 0;

        height: auto;

        .omega {
          position: absolute;
          top: calc(50% - 48px);
          left: 50%;
          transform: translate(-50%, -50%);

          border-radius: .5rem;
          font-size: 2rem;
          line-height: 2.5rem;
          padding: 1rem;

          min-height: 4.625rem;

          .animation {
            padding-left: 3.5rem;

            &::after {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }
    }
  }
}
