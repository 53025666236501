%menu {
  display: none;

  @include breakpoint (large) {
    height: calc(100vh - 5.5rem);
    width: 10rem;

    display: block;

    order: 1;

    flex: 0 0 10rem; // 160

    background-color: $menu-background-color;
    color: $menu-color;
    border-right: 1px solid $menu-border-color;

    overflow: auto;

    z-index: 0;

    > ul {
      display: block;

      border: none;
      margin: 0;
      padding: 0;
      outline: 0;

      list-style-type: none;

      width: 100%;

      > li {
        display: block;

        border: none;
        margin: 0;
        padding: 0;
        outline: 0;

        list-style-type: none;

        width: 100%;

        background-color: $menu-item-top-level-background-color;
        color: $menu-item-top-level-color;
        border-bottom: solid 1px $menu-item-top-level-border-color;

        text-align: center;

        cursor: pointer;

        max-height: 9.625rem;
        transition: max-height .5s ease;

        overflow: hidden;

        &.choose-your-seven {
          max-height: 9.625rem;
          height: 9.625rem;

          .title img {
            width: 46px;
            height: 46px;
          }
        }

        &.choose-your-pack {
          max-height: 7.75rem;
          height: 7.75rem;

          &.disabled {
            display: none;
          }

          .title img {
            width: 46px;
            height: 15px;
          }
        }

        &.choose-your-specs {
          min-height: 10rem;
          max-height: 10rem;
          transition: max-height .5s ease;

          .title {
            position: relative;

            padding-bottom: 2rem;

            overflow: hidden;

            &::after {
              position: absolute;

              content: "\e800";

              height: 1.375rem;
              overflow: hidden;

              left: 0;
              right: 0;
              bottom: 0.5rem;

              font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
              font-weight: 700;
              font-size: 1rem;
              speak: none;

              line-height: 1.375rem;

              text-align: center;

              transform: rotate(0);

              transition: transform .5s;
            }

            img {
              width: 36px;
              height: 36px;
            }
          }
        }

        &.choose-your-view .title img {
          width: 46px;
          height: 15px;
        }

        &.active {
          background-color: $menu-item-top-level-active-background-color;
          color: $menu-item-top-level-active-color;

          max-height: 100vh;
          transition: max-height .5s ease;

          .title {
            h3,
            p {
              background-color: transparent;
              color: $menu-item-top-level-active-color;
            }
          }

          &.choose-your-specs {
            .title {
              padding-bottom: 1.625rem;

              &::after {
                bottom: 2px;

                background-color: transparent;
                color: $menu-item-top-level-active-color;

                transform: rotate(180deg);

                transition: transform .5s;
              }
            }

            .panel > li:first-child {
              padding-top: 0.375rem;
            }
          }
        }

        .title {
          padding: 1rem;

          h3 {
            display: block;

            font-family: "Montserrat Bold", sans-serif;
            font-style: normal;
            font-weight: 400;

            font-size: 0.75rem; // (14)
            line-height: 1rem; // (16)

            color: $menu-item-title-heading-color;

            text-transform: uppercase;

            margin: 0 0 .75rem 0;
          }

          p {
            display: block;

            font-family: "Open Sans Regular", sans-serif;
            font-style: normal;
            font-weight: 700;

            font-size: 0.75rem; // (12)
            line-height: 1.125rem; // (20)

            color: $menu-item-title-body-color;

            text-transform: uppercase;

            margin: .75rem 0 0 0;
          }

          img {
            display: block;

            margin: 0 auto;

            mix-blend-mode: multiply;
          }
        }

        .panel {
          display: block;

          margin: 0.875rem 0;
          padding: 0;

          list-style-type: none;

          > li {
            font-family: "Open Sans Regular", sans-serif;
            font-weight: 400;

            font-size: 0.875rem; // (14)
            line-height: 1.25rem; // (20)

            background-color: $menu-item-sub-level-background-color;
            color: $menu-item-sub-level-color;
            border: none;

            text-align: left;

            text-transform: uppercase;

            padding: .375rem 2rem;

            &:first-child {
              padding-top: 0rem;
            }

            &.active {
              font-family: "Open Sans Bold", sans-serif;
              font-weight: 400;

              font-size: 0.875rem; // (14)
              line-height: 1.25rem; // (20)

              background-color: $menu-item-sub-level-active-background-color;
              color: $menu-item-sub-level-active-color;
            }
          }
        }
      }
    }

    .view-summary,
    .send-to-a-retailer {
      display: flex;
      justify-content: center;

      overflow: hidden;

      text-align: center;

      cursor: pointer;

      padding: 0;
      outline: 0;

      .title {
        padding: 1rem;

        h3 {
          display: block;

          font-family: "Montserrat Bold", sans-serif;
          font-style: normal;
          font-weight: 400;

          font-size: 0.75rem; // (14)
          line-height: 1rem; // (16)

          text-transform: uppercase;

          margin: 0 0 .75rem 0;
        }

        p {
          display: block;

          font-family: "Open Sans Regular", sans-serif;
          font-style: normal;
          font-weight: 700;

          font-size: 0.75rem; // (12)
          line-height: 1.125rem; // (20)

          text-transform: uppercase;

          margin: .75rem 0 0 0;
        }
      }
    }

    .view-summary {
      background-color: $menu-item-view-summary-background-color;
      color: $menu-item-view-summary-color;
      border-bottom: solid 1px $menu-item-view-summary-border-color;

      button {
        display: block;
        width: 100%;

        background-color: transparent;
        color: $menu-item-view-summary-color;

        border: none;
        margin: 0;
        padding: 0;
        outline: 0;

        text-decoration: none;

        .title {
          h3 {
            color: $menu-item-view-summary-title-heading-color;
          }

          p {
            color: $menu-item-view-summary-title-body-color;
          }

          img {
            display: block;

            margin: 0 auto;

            mix-blend-mode: multiply;

            width: 46px;
            height: 15px;
          }
        }

        &:active {
          background-color: $menu-item-view-summary-active-background-color;
          color: $menu-item-view-summary-active-color;

          .title {
            h3,
            p {
              color: $menu-item-view-summary-active-color;
            }
          }
        }
      }
    }

    .send-to-a-retailer {
      background-color: $menu-item-send-to-a-retailer-background-color;
      color: $menu-item-send-to-a-retailer-color;
      border-bottom: solid 1px $menu-item-send-to-a-retailer-border-color;

      button {
        display: block;

        width: 100%;

        background-color: transparent;
        color: $menu-item-send-to-a-retailer-color;

        border: none;
        margin: 0;
        padding: 0;
        outline: 0;

        text-decoration: none;

        .title {
          h3,
          p {
            color: $menu-item-send-to-a-retailer-color;
          }
        }

        &:active {
          background-color: $menu-item-send-to-a-retailer-active-background-color;
          color: $menu-item-send-to-a-retailer-active-color;

          .title {
            h3,
            p {
              color: $menu-item-send-to-a-retailer-active-color;
            }
          }
        }
      }
    }
  }
}
