@import "./configurator/view";
@import "./configurator/menu";
@import "./configurator/options";

@import "./configurator/summary-preview";
@import "./configurator/summary-options";

%configurator {
  position: relative;

  background-color: $hi-grey;

  flex: 1 1 100%;

  /*
   *  Below `footer` and `summary`
   *
   *  Specific for Firefox :(
   */
  z-index: 0;

  overflow: hidden;

  .configure {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    #view {
      @extend %view;
    }

    #menu {
      @extend %menu;
    }

    #options {
      @extend %options;
    }
  }

  @include breakpoint (large) {
    order: 2;

    background-color: $standard-grey;

    .configure {
      flex-direction: row;
    }
  }

  #summary-preview {
    @extend %summaryPreview;
  }

  #summary-options {
    @extend %summaryOptions;
  }
}
