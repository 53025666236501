@import "./common";

/**
 *  Applies to all except `#models`
 */
%strap {
  @mixin accordion {
    min-height: 2.375rem; // 38
    max-height: 2.375rem; // 38

    h4 {
      min-height: 2.375rem; // 38
      max-height: 2.375rem; // 38

      &::after {
        position: absolute;

        top: 0.5rem; // 8
        right: 1rem;
        bottom: 0.5rem; // 8

        transform: rotate(180deg);

        transition: transform .5s;
      }
    }

    &.expanded {
      max-height: 200vh;

      h4::after {
        transform: rotate(0);
      }
    }
  }

  @mixin paintedNosebandFullDecalPacksAccordion {
    min-height: 3.75rem; // 60
    max-height: 3.75rem; // 60

    h4 {
      min-height: 3.75rem; // 60
      max-height: 3.75rem; // 60

      &::after {
        top: 19px;
        bottom: 19px;
      }

      span {
        width: 17rem;
      }
    }

    &.expanded {
      max-height: 200vh;
    }
  }

  @mixin panel {
    background-color: $panel-background-color;
    color: $panel-color;

    /**
     *
     */
    label {
      /**
       *  Positioned <input /> elements are covered by this
       */
      background-color: $panel-label-background-color;
      color: $panel-label-color;

      /**
       *
       */
      &.thumbnail {
        min-height: 4.125rem; // (66)

        padding-left: 4.75rem; // (76)

        img {
          position: absolute;

          top: .5rem; // (8)
          left: 0.875rem; // (14)

          width: 50px;
          height: 50px;

          display: block;

          border: none;
          margin: 0;
          padding: 0;
          outline: 0;
        }

        &::after {
          left: 4.75rem; // (76)
        }
      }
    }

    &.colours {
      background-color: $panel-label-background-color;
      color: $panel-label-color;

      label {
        background-color: $panel-label-background-color;
        color: $panel-label-color;

        /**
         *  Required for tooltips
         */
        overflow: visible;

        &.thumbnail {
          min-height: unset;

          padding: 0;

          img,
          span {
            display: none;
          }
        }
      }

      .range {
        h6 {
          display: flex;
          flex-direction: row;

          border: none;
          margin: .5rem 0;
          padding: 0 .25rem 0 .25rem;
          outline: 0;

          font-style: normal;
          font-weight: 400;

          font-size: 0.875rem; // (14)
          line-height: 1.125rem; // (18)

          background-color: $panel-range-background-color;
          color: $panel-range-color;

          text-transform: none;

          overflow: hidden;

          span {
            border: none;
            margin: 0;
            padding: 0;
            outline: 0;

            &.title {
              display: block;

              flex-grow: 1;

              background-color: $panel-range-title-background-color;
              color: $panel-range-title-color;

              font-size: 0.875rem; // (14)
              line-height: 1.125rem; // (18)
            }

            &.price {
              display: block;

              flex-shrink: 0;

              font-family: "Open Sans Bold", sans-serif;
              font-style: normal;
              font-weight: 700;

              background-color: $panel-range-title-background-color;
              color: $panel-range-price-color;

              font-size: 0.875rem; // (14)
              line-height: 1.125rem; // (18)

              text-align: right;

              padding-left: 1rem;
            }
          }
        }

        &:first-of-type h6 {
          margin-top: 0;
        }
      }
    }
  }

  width: 100%;
  height: auto;

  max-height: none;
  min-height: auto;

  overflow-x: hidden;
  overflow-y: visible;

  font-family: "Open Sans Regular", sans-serif;
  font-style: normal;
  font-weight: 400;

  color: $standard-body;

  font-size: 0.75rem;
  line-height: 1.125rem;

  // Super Small
  @media (max-width: 26rem) { // 416px) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    #painted-noseband-full-decal-packs.accordion {
      @include paintedNosebandFullDecalPacksAccordion;
    }
  }

  @include breakpoint (extra-small) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    .accordion {
      @include accordion;
    }

    #painted-noseband-full-decal-packs.accordion {
      @include paintedNosebandFullDecalPacksAccordion;
    }
  }

  @include breakpoint (small) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    .accordion {
      @include accordion;
    }

    #painted-noseband-full-decal-packs.accordion {
      @include paintedNosebandFullDecalPacksAccordion;
    }
  }

  @include breakpoint (medium) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    .accordion {
      @include accordion;
    }

    #painted-noseband-full-decal-packs.accordion {
      @include paintedNosebandFullDecalPacksAccordion;
    }
  }

  @include breakpoint (large) {
    font-size: 0.75rem;
    line-height: 1.125rem;

    min-height: 0;
    max-height: 100%;
  }

  .accordion {
    max-height: 2.375rem;
    min-height: auto;

    font-family: "Open Sans Regular", sans-serif;
    font-style: normal;
    font-weight: 400;

    border-bottom: 1px solid $accordion-background-color;
    color: $accordion-color;

    font-size: 0.75rem;
    line-height: 1.125rem;

    transition: max-height .5s ease-in-out;

    /**
     *  Ensure that when the accordion is collapsed elements do not continue to
     *  "bleed through"
     */
    overflow: hidden;

    &:last-of-type {
      border-bottom: none;
    }

    h4 {
      position: relative;

      display: block;

      font-family: "Open Sans Regular", sans-serif;
      font-style: normal;
      font-weight: 400;

      background-color: $accordion-title-background-color;
      color: $accordion-title-color;

      font-size: 0.875rem; // (14)
      line-height: 1.375rem; // (22)

      text-transform: uppercase;

      border: none;
      margin: 0;
      padding: 0;
      outline: 0;

      cursor: pointer;

      min-width: 20rem;

      span {
        display: block;
        padding: .5rem 3rem .5rem 1rem;

        cursor: default;
      }

      &::after {
        content: "\e800";

        height: 1.375rem;
        overflow: hidden;

        font-family: fontello; // stylelint-disable-line font-family-no-missing-generic-family-keyword
        font-weight: 700;
        font-size: 1rem;
        speak: none;

        line-height: 1.375rem;

        text-align: center;
      }
    }

    &.disabled {
      display: none;
    }

    .bellows {
      text-transform: none;

      width: 100%;
      height: auto;

      background-color: $accordion-bellows-title-background-color;
      color: $accordion-bellows-title-color;

      h5 {
        position: relative;

        display: block;

        border: none;
        margin: 0;
        padding: 0;
        outline: 0;

        cursor: pointer;

        background-color: $accordion-bellows-title-background-color;
        color: $accordion-bellows-title-color;

        text-transform: none;

        font-style: normal;
        font-weight: 400;

        font-size: 0.875rem; // (14)
        line-height: 1.125rem; // (18)

        span {
          display: block;

          border: none;
          margin: 0;
          padding: .5rem 1rem;
          outline: 0;

          cursor: default;
        }
      }

      /**
       *  All groups except Models
       */
      .group {
        @include group;

        .panel {
          @include panel;
        }
      }
    }

    @include breakpoint (large) {
      flex: 1 1 100%;

      .bellows {
        flex: 1 1 100%;

        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}
