%tooltip {
  position: absolute;

  z-index: 1;

  width: auto;
  height: auto; // 18px'

  opacity: 0;

  display: block;

  overflow: hidden;

  border: none;
  margin: 0;
  padding: 0;
  outline: 0;

  white-space: nowrap;

  text-align: center;

  transition: opacity .5s, top .5s ease, left .5s ease, transform .5s ease;

  background-color: gold;

  .text-content {
    display: block;

    overflow: visible;

    background-color: #fff;
    border: 1px solid #ccc;
    margin: 0;
    padding: 5px 11px;
    outline: 0;

    width: 100%;
    height: 100%;
  }

  &.active {
    opacity: 1;
  }

  &.mezzo {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &.above {
    top: -36px;
    left: -5px;

    transform: translate(-0%, -0%);
  }
}
