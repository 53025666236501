@import "./common";

%summaryPreview {
  @extend %summary;

  display: flex;
  flex-direction: column;

  // background-color: $summary-preview-background-color;
  background: radial-gradient(ellipse at center, $summary-preview-primary-color 25%, $summary-preview-accent-color 100%);

  overflow: hidden;

  .banner {
    border-bottom: 1px solid $summary-preview-border-color;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .actions {
    .send-to-a-retailer {
      display: inline-block;
      white-space: nowrap;

      a {
        @extend %actionsAnchor;
      }
    }
  }

  .content {
    flex: 1 1 100%;
    overflow: hidden;

    position: relative;

    img {
      display: block;

      opacity: 0;
      transition: opacity .5s ease-in;

      position: absolute;

      width: 100%;

      object-fit: contain;

      @include breakpoint (extra-small, portrait) {
        top: 0;
        bottom: 0;
        height: 100%;
        transform: none;
      }

      @include breakpoint (extra-small, landscape) {
        top: 50%;
        bottom: 50%;
        height: 100%;
        transform: translateY(-50%);
      }

      /**
       *  As Extra Small Portrait
       */
      @include breakpoint (small, portrait) {
        top: 0;
        bottom: 0;
        height: 100%;
        transform: unset;
      }

      /**
       *  As Extra Small (Landscape)
       */
      @include breakpoint (small, landscape) {
        top: 50%;
        bottom: 50%;
        height: 100%;
        transform: translateY(-50%);
      }

      /**
       *  As Small (Portrait) and Extra Small (Portrait)
       */
      @include breakpoint (medium, portrait) {
        top: 0;
        bottom: 0;
        height: 100%;
        transform: unset;
      }

      /**
       *  Or with pinned Prices
       **//*
      @include breakpoint (medium, portrait) {
        top: 0;
        bottom: unset;
        height: auto;
        left: 50%;
        right: 50%;
        width: 100%;
        transform: translateX(-50%);
      } */

      /**
       *  As Small (Landscape) and Extra Small (Landscape)
       */
      @include breakpoint (medium, landscape) {
        top: 50%;
        bottom: 50%;
        height: 100%;
        transform: translateY(-50%);
      }

      @include breakpoint (large, portrait) {
        top: 0;
        bottom: unset;
        height: auto;
        left: 50%;
        right: 50%;
        width: 100%;
        transform: translateX(-50%);
      }

      @include breakpoint (large, landscape) {
        top: 0;
        bottom: unset;
        left: 50%;
        right: 50%;
        height: 100%;
        width: auto;
        transform: translateX(-50%);
      }

      /**
       *  As Large (Portrait)
       */
      @include breakpoint (extra-large, portrait) {
        top: 0;
        bottom: unset;
        height: auto;
        left: 50%;
        right: 50%;
        width: 100%;
        transform: translateX(-50%);
      }

      /**
       *  As Large (Landscape)
       */
      @include breakpoint (extra-large, landscape) {
        top: 0;
        bottom: unset;
        left: 50%;
        right: 50%;
        height: 100%;
        width: auto;
        transform: translateX(-50%);
      }

      &.hide {
        opacity: 0;
      }

      &.show {
        opacity: 1;
      }
    }
  }

  @include breakpoint (large) {
    z-index: 5;

    .banner p {
      display: none;
    }
  }
}
