%summary {
  position: absolute;

  height: 100%;

  right: 0;
  bottom: -100%;
  left: 0;

  transition: bottom .5s ease;

  /*
   *  Below `footer`
   */
  @include breakpointExtraSmall {
    z-index: 1;
  }

  @include breakpointSmall {
    z-index: 1;
  }

  @include breakpointMedium {
    z-index: 1;
  }

  &.active {
    bottom: 0;
  }

  h2 {
    margin: 0 1rem 1rem 1rem;

    font-family: "Open Sans Bold", sans-serif;
    font-style: normal;
    font-weight: 400;

    background-color: $summary-title-background-color;
    color: $summary-title-color;

    font-size: 2.25rem; // (36)
    line-height: 2.625rem; // (42)

    text-align: center;

    @include breakpointMedium {
      font-size: 2.5rem; // (40)
      line-height: 3rem; // (48)
    }

    @include breakpointLarge {
      font-size: 2.5rem; // (40)
      line-height: 3rem; // (48)
    }

    @include breakpointExtraLarge {
      font-size: 2.5rem; // (40)
      line-height: 3rem; // (48)
    }
  }

  h3 {
    margin: 1.5rem 0;

    font-family: "Montserrat Bold", sans-serif;
    font-style: normal;
    font-weight: 400;

    background-color: $summary-title-background-color;
    color: $summary-title-color;

    font-size: 1.375rem; // (22)
    line-height: 1.875rem; // (30)

    text-align: center;

    @include breakpointMedium {
      margin: 2rem 0;
    }

    @include breakpointLarge {
      margin: 2rem 0;
    }

    @include breakpointExtraLarge {
      margin: 2rem 0;
    }
  }

  p {
    text-align: center;
    text-transform: uppercase;
  }

  .banner {
    padding: 2.5rem 0; // 3rem 0;

    width: 100%;

    @include breakpointMedium {
      padding: 2.625rem 0; // 3rem 0;
    }

    @include breakpointLarge {
      padding: 2.625rem 0; // 3rem 0;
    }

    @include breakpointExtraLarge {
      padding: 2.625rem 0; // 3rem 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .actions {
    @extend %actions;

    align-items: center;
    justify-content: center;

    padding-top: 0;
    padding-bottom: 0;

    @include breakpointExtraSmall {
      padding: 0;
    }

    @include breakpointSmall {
      padding: 0;
    }
  }

  .groups {
    padding: 0 1rem 1rem;

    width: 100%;

    @include breakpointSmall {
      padding: 0 2rem 2rem;
    }

    @include breakpointMedium {
      padding: 0 2.5rem 2.5rem;
    }

    @include breakpointLarge {
      padding: 0 3rem 3rem;
    }

    @include breakpointExtraLarge {
      padding: 0 3rem 3rem;
    }

    .group {
      display: block;

      max-width: 90rem;
      width: 100%;

      padding: 0.75rem 0;
      margin: 0 auto;

      overflow: hidden;

      border-bottom: 1px solid $summary-options-selected-border-color;

      &:first-of-type {
        border-top: 1px solid $summary-options-selected-border-color;
      }

      &.disabled {
        display: none;
      }

      h4 {
        display: block;

        font-family: "Open Sans Bold", sans-serif;
        font-style: normal;
        font-weight: 400;

        background-color: $summary-options-title-background-color;
        color: $summary-options-title-color;

        font-size: 0.875rem; // (14)
        line-height: 1.25rem; // (20)

        float: left;
        clear: left;

        margin: 0;
        padding: 0;
        border: none;
      }

      .selected {
        display: block;

        font-size: 0.875rem; // (14)
        line-height: 1.25rem; // (20)

        .name {
          display: inline-block;

          font-family: "Open Sans Regular", sans-serif;
          font-style: normal;
          font-weight: 400;

          background-color: $summary-options-selected-name-background-color;
          color: $summary-options-selected-name-color;
        }

        .price {
          display: block;

          font-family: "Open Sans Bold", sans-serif;
          font-style: normal;
          font-weight: 400;

          background-color: $summary-options-selected-price-background-color;
          color: $summary-options-selected-price-color;

          float: right;
          clear: right;

          margin-left: 1rem;
        }
      }
    }
  }
}
